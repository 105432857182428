import { useStaticQuery, graphql } from 'gatsby';

// TODO: Replace 'id' with '_id' in TEAMS since 'id' - internal value of Sanity not object id
const useHammarbyClub = () => {
    const {
        sanityClub: hammarbyClub,
    } = useStaticQuery(graphql`
        query hammarbyClub {
            sanityClub(isHammarby: {eq: true}) {
                id
                title
                teams {
                    _id
                    id
                    title {
                        en
                        se
                    }
                    playerpage_visible
                    allowTicketsSales
                    allowSeasonPassSales
                    axsType
                    slug {
                        current
                    }
                }
            }
        }
    `);

    return hammarbyClub;
};

export default useHammarbyClub;
