import React, { useContext } from 'react';
import { Link } from 'gatsby';

import * as style from 'styles/components/player/list.module.scss';
import { LangContext } from 'context/LangContext';
import useHammarbyClub from 'data/queries/useHammarbyClub';
import PlayerItem from 'components/player/Item';
import FormattedMessage from 'components/utils/FormattedMessage';

const PlayerList = ({ players }) => {
    const { lang } = useContext(LangContext);
    const club = useHammarbyClub();

    // if player doesn't have position, set to midfileder to avoid build error
    players.forEach((player) => {
        if (player.position === null) {
            player.position = 'outfieldPlayer';
        }
    });
    // sort players by position
    const positionsOption = [...new Set(players.map((p) => p.position))];
    const sortPosition = ['goalkeeper', 'backend', 'midfielder', 'forward', 'outfieldPlayer', 'onloan', 'management'];
    const playersByPosition = [];

    positionsOption.forEach((position) => {
        const playersForPosition = players.filter((item) => item.position === position);
        if (playersForPosition.length > 0) {
            playersByPosition.push({ position, players: playersForPosition });
        }
    });
    playersByPosition.sort((a, b) => sortPosition.indexOf(a.position) - sortPosition.indexOf(b.position));

    playersByPosition.forEach((item) => {
        if(item.position === 'management') {
            item.players.sort((a, b) => a.sortOrder - b.sortOrder);
        } else {
            item.players.sort((a, b) => a.number - b.number);
        }
    });

    return (
        <div>
        <ul className="tabs wrapper">
            {club.teams
                .filter(
                    (item) =>
                        item.title &&
                        item.title[lang.translationKey] &&
                        item.playerpage_visible,
                )
                .map((item, index) => (
                    <li key={index}>
                         <Link
                            activeClassName="active"
                            partiallyActive={false}
                            to={`/spelare/${item.slug.current}`}>
                            {item.title[lang.translationKey]}
                        </Link>

                    </li>
                ))}
        </ul>

        <div className="wrapper">
            {playersByPosition.length > 0 &&
                playersByPosition.map((item, index) => (
                    <div key={index} id={item.position} className={style.playerList__container}>
                        {item.players
                                .filter(
                                    (player) =>
                                        player.visible,
                                ).length > 0 && (
                        <h2 className="title-sm bold">
                            <FormattedMessage id={`player.position-${item.position}`} />
                        </h2>
                        )}

                        <ul className={style.playerList__list}>
                            {item.players
                                .filter(
                                    (player) =>
                                        player.visible,
                                )
                                .map((player, playerIndex) => (
                                    <li key={playerIndex}>
                                        <PlayerItem player={player} />
                                    </li>
                                ))}
                        </ul>
                    </div>
                ))}
        </div>
    </div>
    );
};

export default PlayerList;
