import * as React from 'react';
import MetaTags from 'react-meta-tags';

import PlayerList from 'components/player/List';

const playersList = ({ pageContext: { page, players } }) => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const path = typeof window !== 'undefined' ? window.location.pathname : '';
    if( path === '/spelare' || path === '/spelare/' ) {
        window.location.href = '/spelare/herr';
    }
    return (
        <>
            {page.seo && (
                <MetaTags>
                    <title>{page.seo.title}</title>
                    <meta name="description" content={page.seo.description} />

                    <meta property="og:title" content={page.seo.title} />
                    <meta property="og:description" content={page.seo.description} />
                    <meta property="og:url" content={url} />
                    <meta property="og:site_name" content="Hammarby" />
                    <meta property="og:type" content="teams" />

                    <link rel="canonical" href={url} />
                </MetaTags>
            )}

            <PlayerList players={players} />
        </>
    );
};

export default playersList;
